body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #222;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}
#login {
  margin:40px auto;
  max-width:550px;
  padding:20px;


}
#loginInner {
  border-radius:4px;
  border:1px solid #ccc;
  padding:20px;
  background:#eee;
}
.gray {
  color:gray;
}
a.gray:hover {
  color:white !important;
}

h2 {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.enabled {
  background-color: #B3E5FC;
}

.disabled {
  background-color: #eee;
}

.generateLicences {
  border: 1px solid #ddd;
  padding: 1em;
  margin-bottom: 2em;
  margin-top: 2em;
}

.alignLeft {
  text-align: left;
}

.bottomContainer {
  margin-bottom: 2em;
  margin-top: 2em;
}
.floatyBackButton {
  float:left;
  margin-right:-83px;

}
.fixedCells td {
  width:50%;
}
.w25 td {
  width:25%;
}
.w33 td {
  width:33.33%;
}
.halfWidthAlert [role="alert"] {
  width: calc(50% - 20px);
  display: inline-block;
  margin: 10px;
}

.alert, .spinner-border {
  margin-top: 1em;
  margin-bottom: 1em;
}

.input-group-prepend {
  width : 33%;
}

.input-group-prepend .input-group-text {
  width: 100%;
  overflow: hidden;
}

.input-group-prepend {

}
.inputGroupLike {
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  display:inline-block;
  margin:5px;
  margin-top:0px;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  vertical-align: 5px;
  align-items: center;
  height:38px;
}
.inputGroupLike input {
  margin-right:6px;
}
#search {
  text-align: left;
  margin-left:-5px;
  user-select: none;
}
.table-hover td {
  cursor:pointer;
}
 .monospace {
  font-family: monospace;
  color:#444;
  font-size:14px;
}

 table p {
   line-height: 16px;
 }

 .preLine {
   white-space: pre-line;
 }

 .table td, .table th {
   padding: 8px;
 }

 .nowrap {
   white-space: nowrap;
 }

 .pre {
   white-space: pre;
 }
